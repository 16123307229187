import * as React from "react";
import Helmet from "react-helmet";
import Container from "../components/container";
import { A2 } from "../components/fonts";

const JazzHrIframe = () => {
  return (
    <iframe
      src="/jazzhr.html"
      style={{ border: 0, width: "100%", height: "100%", minHeight: 600 }}
    />
  );
};

const OpenRolesPage = () => {
  return (
    <>
      <Helmet title="Careers" />
      <Container className="bg-wbStone">
        <div className="col-span-30 px-6 my-32 bg-wbStone">
          <div className="w-full md:w-6/12 mx-auto">
            <A2>Open Roles</A2>
            <div className="py-8">
              <JazzHrIframe />
            </div>
          </div>
        </div>
        <div className=""></div>
      </Container>
    </>
  );
};
export default OpenRolesPage;
